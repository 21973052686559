@font-face {
  font-family: "SF Pro Display";
  src: url("./../assets/fonts/SFProDisplay.ttf");
}

body {
  font-family: "SF Pro Display" !important;
}

.form-group.categorySearch {

  >input,
  >button {
    border-radius: 0 !important;
  }
}

input[type="number"] {
  text-align: left !important;
  padding: 0.5rem 1rem !important;

  &::-webkit-input-placeholder,
  &::placeholder {
    text-align: left;
  }
}


td {
  vertical-align: middle !important;
  text-align: center !important;
}

th {
  text-align: center !important;
}

.geosuggest__input {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  border-color: #e4e7ea !important;

  &:focus {
    outline: 0;
  }
}

.fa-vendor {
  background-image: url("./../assets/images/vendor.png");
}

%input {
  height: 52px !important;
  background-color: #fff;
  border: 1px solid #e4e4e4 !important;
  border-radius: 7px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 7%) !important;
  font-family: "SF Pro Display" !important;
  font-size: 16px !important;
  font-weight: normal !important;
  max-width: 353px;
  color: #5c6873 !important;
  &:focus {
    outline: 0 !important;
  }

  @media (max-width: 991px) {
    max-width: 100%;
  }
}

.input-group {
  >input {
    @extend %input;
    border-radius: 0 !important;
  }
}

form {

  .form-group,
  .react-datepicker__input-container,
  .input-group {

    >input,
    textarea,
    .geosuggest__input {
      @extend %input;
    
      &.is-invalid {
        border-color: #e55353 !important;
      }

      &.is-valid {
        border-color: #2eb85c !important;
      }

      &:hover,
      &:focus {
        border-color: #00a1ed !important;
      }

      &.disabled,
      &:disabled {
        border-color: #f9f9f9 !important;
        color: #d1d1d1 !important;
      }

      &:foucs {
        outline: 0 !important;
      }
    }

    textarea {
      height: unset !important;
      max-width: 100% !important;
    }

    button:not(.react-datepicker__navigation) {
      height: 50px !important;
      border-radius: 7px;
      font-family: "SF Pro Display" !important;
      text-transform: capitalize !important;
      font-size: 16px !important;
      margin-top: 1rem;
    }

    .btn-primary {
      background-color: #00a1ed !important;
      box-shadow: 0 5px 5px rgba(0, 161, 237, 18%) !important;
    }

    button[type="reset"] {
      display: none !important;
    }

    button[type="button"] {
      &.btn-success {
        background-color: #efefef !important;
        color: #afafaf !important;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 4%) !important;
      }
    }
  }
}

.customBtn {
  height: 50px !important;
  border-radius: 7px;
  font-family: "SF Pro Display" !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  background-color: #00a1ed !important;
  box-shadow: 0 5px 5px rgba(0, 161, 237, 18%) !important;

  &:disabled {
    background-color: #efefef !important;
    color: #afafaf !important;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 4%) !important;
  }
}

.globalCard {
  background-color: #fff;

  .card-body {
    @media (min-width: 991px) {
      padding: 3rem 3rem !important;

      label {
        display: block;
      }
    }
  }
}

label[for="activated"] {
  position: absolute;
  top: 15px;
  left: 40px;
  padding-left: 25px;
  z-index: 5;
  padding-top: 3px;
  font-size: 1.2rem;
  font-weight: 500;
}

.custom-control-input {
  &:checked {
    ~.custom-control-label::before {
      color: #fff;
      border-color: #00a1ed !important;
      background-color: #00a1ed !important;
    }

    ~.custom-control-label::after {
      width: 32px !important;
      height: 32px !important;
    }
  }

  ~.custom-control-label::before {
    width: 32px !important;
    height: 32px !important;
    border-color: #e4e4e4 !important;
  }
}

input[type="email"]:disabled.text-dark {
  color: #000 !important;
}

h4 {
  font-size: 22px !important;
  font-family: "SF Pro Display" !important;
}

.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}

.react-time-picker {
  width: 100%;

  .react-time-picker__wrapper {
    @extend %input;
    width: 100% !important;

    .react-time-picker__inputGroup {
      display: flex !important;
      align-items: center;

      input {
        outline: 0 !important;
        width: auto !important;
        min-width: auto !important;
        flex: 1 1 33%;
        border: 0 !important;

        &:focus {
          outline: 0 !important;
        }
      }

      select {
        flex: 1 1 45%;

        &:focus {
          outline: 0 !important;
        }
      }
    }
  }

  .react-time-picker__clear-button {
    height: 25px !important;
    margin-top: 0rem !important;
    display: none !important;
  }
}

.react-time-picker__clock--open,
.react-time-picker__clock-button {
  display: none !important;
  visibility: hidden !important;
}

.react-time-picker__inputGroup__leadingZero {
  visibility: hidden !important;
}

.exportBtn {
  width: 141px;
  height: 50px !important;
  margin-bottom: 0;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 7%) !important;
  font-size: 16px !important;
  font-family: "SF Pro Display" !important;
  position: relative;
  border: 1px solid #777 !important;
  padding-left: 2.3rem !important;

  &::after {
    content: "";
    width: 16px;
    height: 16px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAABHNCSVQICAgIfAhkiAAAAMxJREFUKFPFkkEOwiAQRSXtAXTvHdQT2O7byFF6MzFtupXeoN7BvR4AUv83QirSJiYmsgGG/4bPDGIxMcqy1ANG0zR5TCJmwIFndV1HNX8G8S6ZJIlWSt2x9lallEtr7R62T+5p3iohBI+oR5+maQ7hjSIkWhljzkKILWK7tm17xj3IrBBoCDaEKaTArTFfkDCjmzeQmzE8rnYIPcGwXyEcQk5P8KNfL/jKzLC3dva4d/ooOPUpfgYqZDrM3RI560RRFBlKX30Bd+hn9QBLOpQZQBogaQAAAABJRU5ErkJggg==);
    position: absolute;
    left: 37px;
    background-repeat: no-repeat;
    top: 16px;
  }
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.react-datepicker__input-container {
  position: relative;
  max-width: 353px;

  @media (max-width: 991px) {
    max-width: 100%;
  }

  >input {
    background-color: transparent !important;

    &:focus {
      outline: 0 !important;
    }
  }

  &::after {
    content: "\f133";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.geosuggest__input-wrapper {
  max-width: 353px;

  @media (max-width: 991px) {
    max-width: 100%;
  }

  input {
    @extend %input;
  }
}

input[type="file"] {
  &::-webkit-file-upload-button {
    height: 50px;
  }
}

.card-header {
  img[alt="Logo"] {
    margin: auto;
    height: 60px;
  }

  ~.card-body {
    form {

      >h1,
      >p.text-muted {
        text-align: center;
      }

      .input-group {
        justify-content: center;
      }

      .col-6 {
        margin: auto;
        text-align: center;

        button.px-4 {
          height: 50px !important;
          border-radius: 7px;
          font-family: "SF Pro Display" !important;
          text-transform: capitalize !important;
          font-size: 16px !important;
          margin-top: 1rem;
          background: #00a1ed !important;
          margin: auto;
        }
      }
    }
  }
}


.text-red {
  color: red;
}

.placeholder-wave {
  cursor: progress;
  color: transparent !important;
  text-decoration: none !important;
  margin-bottom: 0.3rem;

  * {
    color: transparent !important;
    text-decoration: none !important;
    background-color: transparent !important;
    opacity: 0;
  }
}

.langSelect {
  width: 150px;
  position: relative;
  z-index: 5;
}

.geosuggest__suggests-wrapper {
  position: absolute;
  z-index: 5;
  max-width: 353px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
}

.react-bootstrap-table {

  .table-striped,
  .table-bordered {
    border: 0;

    thead {
      tr {
        border-top: 0;
        background-color: #f9fafb !important;

        th {
          padding: 16px 0.75rem;
          font-size: 18px;
          font-weight: 500;
          white-space: nowrap;

          &:first-child {
            border-radius: 7px 0 0 0 !important;
          }

          &:last-child {
            border-radius: 0 7px 0 0 !important;
          }
        }
      }
    }

    tbody {
      tr {
        background-color: transparent !important;

        td {
          font-size: 14px;
          padding: 26px 0.75rem;
        }

        >* {
          --bs-table-accent-bg: transparent;
        }

        &:hover {
          background-color: #f9fafb !important;
        }
      }
    }
  }

  .table-bordered {

    th,
    td {
      border: 0;
    }
  }
}

.table-bordered> :not(caption)>* {
  border-width: 1px 0;
  border-bottom: 1px solid #e7eef2;
}

.table> :not(:first-child) {
  border-top: 2px solid #e7eef2;
}

.tableImg {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.btn-info {
  background-color: #00a1ed !important;
}

.iconBtn {
  i {
    width: auto !important;
    height: auto !important;
    pointer-events: none;
  }
}

.button-tables {
  text-align: center;
  position: relative;

  button {
    position: relative;
    color: transparent !important;
    padding: 0rem !important;
    width: 40px !important;
    height: 40px !important;
    background-color: transparent !important;
    box-shadow: none !important;

    &:hover,
    &:focus {
      color: transparent;
    }

    &::before {
      font-size: 1.5rem;
      -webkit-font-smoothing: antialiased;
      display: var(--fa-display, inline-block);
      font-style: normal;
      font-variant: normal;
      line-height: 1.6;
      text-rendering: auto;
      font-family: "Font Awesome 6 Pro";
      font-weight: 300;
      position: absolute;
    }

    &[title="Delete Record"] {
      &::before {
        content: "\f057";
        color: #e03232;
      }
    }

    &[title="Edit Record"] {
      &::before {
        content: "\f044";
        color: #00a1ed;
        font-weight: 400;
      }
    }
  }

  >button[title="Record Details"] {
    &::before {
      content: "\f8fa";
      color: #2eb85c;
      font-weight: 400;
    }
  }

  a {
    button[title="Record Details"] {
      &::before {
        content: "\f06e";
        color: #2eb85c;
        font-weight: 400;
      }
    }
  }
}

.table-filters {
  form {
    .form-group {
      button {
        display: inline-block !important;
      }
    }
  }
}

.fa-duotone.fa-chart-mixed {
  &:before {
    position: absolute;
    color: #FDD05D;
    opacity: 1;
  }

  &:after {
    opacity: 1;
    color: #FFFFFD;
  }
}


.react-timerange-picker {
  height: 52px !important;
  background-color: #fff;
  border: 1px solid #e4e4e4 !important;
  border-radius: 7px !important;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 7%) !important;
  font-family: "SF Pro Display" !important;
  font-size: 16px !important;
  font-weight: normal !important;

  .react-timerange-picker__wrapper {
    border: 0 !important;
    height: auto;

    &:hover,
    &:focus {
      border-color: #00a1ed !important;
    }

    .react-timerange-picker__inputGroup {
      input {
        border: 0 !important;
        outline: 0 !important;
        color: #5c6873 !important;
        min-width: unset !important;

        &:focus {
          outline: 0 !important;
        }
      }
    }
  }

  select {
    border: 0 !important;
    color: #5c6873;
    &:focus {
      outline: 0 !important;
    }
  }
}

.field-advanceSelect {
  >div {
    &:not(.invalid-feedback) {

      >div {
       >div{
        &:first-of-type {
          min-height: 52px;
          max-width: 353px;
          box-shadow: 0 5px 5px rgba(0, 0, 0, 7%);
          border-radius: 7px;
          font-size: 16px;
          border: 1px solid #e4e4e4;
          color: #5c6873 !important;
          &:hover,
          &:focus {
            border: 1px solid #00a1ed
          }
        }

        &:last-of-type {
          max-width: 353px;
        }
       }
      }
    }
  }
}