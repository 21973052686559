.statisticsCard {
  background-color: #000000;
  background-image: linear-gradient(315deg, #232b3b 0%, #3c4b64 74%);
  color: #fff !important;
  box-shadow: 0px 5px 5px grey !important;
  height: 100%;
  margin-bottom: 0 !important;
  width: 100%;
  .card-body {
    display: flex;
    align-items: center;
  }
  .d-flex {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    small {
      font-size: 11px;
      min-height: 0.8em;
      display: block;
      line-height: 1;
    }
    h3 {
      font-size: 50px;
      margin-bottom: 0;
      min-height: 0.8em;
    }

    img {
      max-width: calc(5px + 12vmin);
      margin-top: 0.8rem;
    }
    .Icon {
      background-color: #fff;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #3c4b64;
      flex: 0 0 50px;
      i {
        font-size: 25px;

        &.fa-shop {
          font-size: 20px;
        }
        &.fa-person-walking {
          font-size: 13px;
          transform: rotateY(180deg);
          padding-top: 10px;
        }
      }
    }
  }
}
.dark {
  background-image: linear-gradient(315deg, #232b3b 0%, #3c4b64 74%);
  box-shadow: 0px 5px 5px grey !important;
}
.flex-center{
  display: flex;
  justify-content:center;
  align-items:center;
}