@font-face {
  font-family: "SF Pro Display";
  src: url("./../../../assets/fonts/SFProDisplay.ttf");
}

.resCard {
  color: #333;
  margin-top: 3rem;
  box-shadow: rgb(0 0 0 / 25%) 0px 0.0625em 0.0625em, rgb(0 0 0 / 25%) 0px 0.125em 0.5em, rgb(255 255 255 / 10%) 0px 0px 0px 1px inset;
  .coverImage {
    //   height: 100%;
    position: relative;
    .mask {
      inset: 0 0 0 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgb(255 255 255 / 30%);
    }
    img {
      width: 130px;
      object-fit: cover;
      height: 100%;

      &.shopLogo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        object-fit: contain;
        box-shadow: 0 5px 5px rgba(255, 255, 255, 0.9) !important;
        border: 2px dashed #333;
      }
    }
  }

  .card-body {
    padding: 0 2rem;
    .shopName {
      font-family: "SF Pro Display";
      margin-top: 1rem;
      text-align: center;
      min-height: 1rem;
    }
    .location {
      margin: auto;
      text-align: center;
      line-height: 1.1;
      font-weight: 400;
      min-height: 1rem;
      font-size: calc(5px + 1.8vmin);
      margin-bottom: 0.5rem;
    }
  }
}
